import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  ViewState,
  EditingState,
  IntegratedEditing,
} from "@devexpress/dx-react-scheduler";
import { SocketIO } from "./utils/helper";
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentForm,
  DragDropProvider,
  EditRecurrenceMenu,
  AllDayPanel,
  ConfirmationDialog,
} from "@devexpress/dx-react-scheduler-material-ui";
import axios from "axios";
import BookingDetail from "./components/bookingdetail";
import PaymentStatus from "./components/paymentstatus";
import DistanceAlert from "./components/alert";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Autocomplete from "react-google-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from "react-select";
import moment from "moment";
import CreateCustomer from "./modules/Customers/CreateCustomer";
import Login from "./modules/Login";

const recurrenceAppointments = [];

const dragDisableIds = new Set([1]);

const allowDrag = ({ id }) => !dragDisableIds.has(id);

const Dashboard = () => {
  const [data, setData] = useState(recurrenceAppointments);
  const [vanData, setVanData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date("2023-10-1"));
  const [previousDate, setPreviousDate] = useState(new Date("2023-10-1"));
  const [nextDate, setNextDate] = useState(new Date("2023-10-1"));
  const [date, setDate] = useState(new Date());
  const [childComponent, setChildComponent] = useState(null);
  const [show, setShow] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [vanLocationData, setVanLocationData] = useState("");
  const [vanDetailsData, setVanDetailsData] = useState("");
  const [vanDriverData, setVanDriverData] = useState([]);
  const [loginKey, setLoginKey] = useState(localStorage.getItem("loginKey"));

  const handleClose = () => setShow(false);

  const handleShow = (van) => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-van-data/" +
          van.id +
          "/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanDetailsData(res.data);
        setShow(true);
      });
  };

  const handleCloseLocation = () => {
    setShowLocation(false);
  };
  const handleShowLocation = (van) => {
    setVanLocationData(van);
    setShowLocation(true);
  };

  const locationUpdateHandler = () => {
    const updateLocation = fetch(
      process.env.REACT_APP_API_BASE_URL + "/update-van-address",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
        body: JSON.stringify(vanLocationData),
      }
    );
    updateLocation
      .then((response) => response.json())
      .then((result) => {
        toast(result.msg);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShowLocation(false);

    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-vans-data/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanData(res.data);
      });
  };

  const updateVanDetailHandler = () => {
    let data = vanDetailsData;
    data.date = localStorage.getItem("currentDate");
    const updateVanDetail = fetch(
      process.env.REACT_APP_API_BASE_URL + "/update-van-data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
        body: JSON.stringify(data),
      }
    );
    updateVanDetail
      .then((response) => response.json())
      .then((result) => {
        toast(result.msg);
        SocketIO.emit("updatebookings", "Update updatebookings");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setShow(false);

    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-vans-data/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanData(res.data);
      });
  };

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-vans-data/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanData(res.data);
      });
  }, [show, setShow, showLocation]);

  useEffect(() => {
    const prevDate = new Date(date.getTime());
    prevDate.setDate(date.getDate() - 1);
    setPreviousDate(prevDate);

    const nextDate = new Date(date.getTime());
    nextDate.setDate(date.getDate() + 1);
    setNextDate(nextDate);

    localStorage.setItem(
      "currentDate",
      moment(date.toLocaleString("en-US", { timeZone: "Asia/Dubai" })).format(
        "YYYY-MM-DD"
      )
    );
  }, [date]);

  useEffect(() => {
    getBookingData();
    getDriverData();

    SocketIO.on("updatebookings", function (data) {
      if (localStorage.getItem("creatingBooking") === "false") {
        getBookingData();
      }
    });
  }, []);

  const getDriverData = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/get-vans-drivers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
      })
      .then((res) => {
        setVanDriverData(res.data);
      });
  };

  const getBookingData = () => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-dashboard-bookings/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        let data = res.data.map((item) => {
          let inputArray = item.startDate.split(", ").map(Number);
          item.startDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          inputArray = item.endDate.split(", ").map(Number);

          item.endDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          item.id = 100 + item.id;
          return item;
        });
        setData(data);
        localStorage.setItem("creatingBooking", false);
      })
      .catch((error) => {
        let res = error.response.data;
        if (res.code === 401 && loginKey) {
          localStorage.removeItem("loginKey");
          setLoginKey(null);
        }
      });
  };

  const getBookingCurrentDate = (date) => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL + "/get-dashboard-bookings/" + date,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        let data = res.data.map((item) => {
          let inputArray = item.startDate.split(", ").map(Number);
          console.log("this is item and tis input", item, " ssd ", inputArray);
          item.startDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          inputArray = item.endDate.split(", ").map(Number);
          item.endDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          item.id = 100 + item.id;
          return item;
        });
        setData(data);
      });
  };

  const openDetail = (id, data) => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/get-booking-detail/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
      })
      .then((res) => {
        setChildComponent(
          <BookingDetail
            otherdata={data}
            bookingData={res.data}
            onClose={onClose}
            onEditBooking={onEditBooking}
            setChildComponent={setChildComponent}
            vanData={vanData}
          />
        );
      });
  };

  const onEditBooking = (data) => {
    toast("Update Successfully");
    setChildComponent(null);
    getBookingData();
  };

  const getVanData = (current_date) => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-vans-data/" +
          new Date(current_date).toISOString().split("T")[0],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanData(res.data);
      });
  };

  const getDashboardBookings = (current_date) => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-dashboard-bookings/" +
          current_date,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        let data = res.data.map((item) => {
          let inputArray = item.startDate.split(", ").map(Number);
          console.log("item and tis input", item, " ssd ", inputArray);

          item.startDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          inputArray = item.endDate.split(", ").map(Number);
          item.endDate = new Date(
            inputArray[0],
            inputArray[1],
            inputArray[2],
            inputArray[3],
            inputArray[4]
          );
          item.id = 100 + item.id;
          return item;
        });
        setData(data);
      });
  };

  const onDateCalenderChange = (e) => {
    if (e) {
      setDate(new Date(e));
      getVanData(e);
      getDashboardBookings(e);
    }
  };

  const getPreviousBooking = () => {
    setDate(new Date(getPreviousDate(localStorage.getItem("currentDate"), 1)));
    let previousDate = getPreviousDate(localStorage.getItem("currentDate"), 1);
    getVanData(previousDate);
    getDashboardBookings(previousDate);
  };

  const changePaymentStatus = (data) => {
    setChildComponent(
      <PaymentStatus
        setLoader={setLoader}
        bookingData={data}
        onClose={() => {
          setChildComponent(null);
          toast("Update Successfully");
          getBookingData();
        }}
      />
    );
  };

  const getNextBooking = () => {
    setDate(new Date(getNextDate(localStorage.getItem("currentDate"), 1)));
    let nextDate = getNextDate(localStorage.getItem("currentDate"), 1);
    getVanData(nextDate);
    getDashboardBookings(nextDate);
  };

  const getPreviousDate = (date, number) => {
    const today = new Date(date);
    const numberOfDaysToAdd = number;
    today.setDate(today.getDate() - numberOfDaysToAdd); // add 7 days

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getNextDate = (date, number) => {
    const today = new Date(date);
    const numberOfDaysToAdd = number;
    today.setDate(today.getDate() + numberOfDaysToAdd); // add 7 days

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const onClose = () => {
    setChildComponent(null);
  };

  const appointmentComponent = (props) => {
    if (allowDrag(props.data)) {
      return (
        <Appointments.Appointment
          {...props}
          style={{
            ...props.style,
            cursor: "pointer",
            background: props.data.color,
          }}
          className="bookingslot"
        >
          <div style={{ padding: "5px" }}>
            <div onClick={() => openDetail(props.data.orgId, props.data)}>
              <strong>{props.data.title} </strong> {props.data.start} -{" "}
              {props.data.end}
              <br />
              <p>
                <strong>
                  {" "}
                  {props.data.mailingstreet
                    ? props.data.mailingstreet + ", "
                    : ""}{" "}
                  {props.data.address}{" "}
                </strong>
              </p>
            </div>
            <div>
              <strong>Pet & Services </strong>
            </div>{" "}
            {props.data.petsWithService.map((pet, index) => (
              <>
                {" "}
                <strong>
                  {" "}
                  {index +
                    1 +
                    ". " +
                    pet.customer_pet.type +
                    " - " +
                    pet.customer_pet.name}
                </strong>
                <br />
                {pet.services.map((service, index) => (
                  <>
                    <span>
                      {index + 1}.{service.label}{" "}
                    </span>
                    <br />
                  </>
                ))}
              </>
            ))}
            <br />
            <strong>Total Amount : {props.data.total_price} AED</strong>
            <br />
            <br />
            <div onClick={() => changePaymentStatus(props.data)}>
              Payment :{" "}
              {props.data.payment === "Unpaid" ? (
                <span style={{ color: "red" }}> Unpaid</span>
              ) : (
                props.data.payment
              )}
            </div>
            <div>From - {props.data.from}</div>
            <br />
            <div>
              <strong>Instruction</strong> : {props.data.description}
            </div>
            <div className="mt-2 mb-2">
              {props.data.booked_by ? (
                <div>
                  <strong>Book by</strong> : {props.data.booked_by}
                </div>
              ) : null}
              {props.data.edited_by ? (
                <div>
                  <strong>Edit by</strong> : {props.data.edited_by}
                </div>
              ) : null}
              {props.data.switch_by ? (
                <div>
                  <strong>Switch by</strong> : {props.data.switch_by}
                </div>
              ) : null}
            </div>
          </div>
        </Appointments.Appointment>
      );
    }
  };

  const onCommitChanges = React.useCallback(
    ({ added, changed, deleted }) => {
      setData((prevData) => {
        let updatedData = [...prevData];
        if (added) {
          if (added.phone) {
            const postCustomer = fetch(
              process.env.REACT_APP_API_BASE_URL + "/create-customer",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: localStorage.getItem("loginKey"),
                },
                body: JSON.stringify(added),
              }
            );
            postCustomer
              .then((response) => response.json())
              .then((result) => {
                toast(result.msg);
                getBookingData();
              })
              .catch((error) => {
                toast(error.message);
              });
          } else {
            //! create booking
            setLoader(true);
            fetch(process.env.REACT_APP_API_BASE_URL + "/create-booking", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginKey"),
              },
              body: JSON.stringify(added),
            })
              .then((response) => response.json())
              .then((result) => {
                if (result.status === 400) {
                  toast(result.message);
                }
                setLoader(false);
                toast(result.msg);
                getBookingCurrentDate(added.date);
                localStorage.setItem("creatingBooking", false);
                SocketIO.emit("updatebookings", "Update updatebookings");
              })
              .catch((error) => {
                toast(error.message);
                setLoader(false);
              });
          }
        }
        if (changed) {
          updatedData = prevData.map((appointment) =>
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment
          );
          // Get the dynamic ID from the changed object
          let dynamicID = Object.keys(changed)[0];
          let foundObject = updatedData.find(
            (obj) => obj.id === parseInt(dynamicID)
          );
          console.log(foundObject, vanData, "this");
          updateBooking(foundObject, vanData);
        }

        if (deleted !== undefined) {
          updatedData = prevData.filter(
            (appointment) => appointment.id !== deleted
          );
          updateBooking(deleted, vanData);
        }

        return updatedData;
      });
    },
    [vanData]
  );

  const updateBooking = (data, vanData) => {
    if (!data) {
      return;
    }
    let van_id = new Date(data.startDate).getDate();
    let van = vanData.filter((item) => {
      return item.id === van_id;
    });

    // let distance = getDistanceFromLatLonInKm(
    //   van[0].lat,
    //   van[0].lng,
    //   data.booking_lat,
    //   data.booking_lng
    // );

    let distance = 11;
    if (distance > 10) {
      setChildComponent(
        <DistanceAlert
          distance={distance}
          data={data}
          onAlertClose={onAlertClose}
        />
      );
    } else {
      onAlertClose(data);
    }
  };

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const onAlertClose = (data) => {
    setChildComponent(null);
    let start_time = null;
    let end_time = null;
    let van_id = 0;
    van_id = new Date(data.startDate).getDate();
    start_time = formateTime(new Date(data.startDate));
    end_time = formateTime(new Date(data.endDate));

    let obj = {
      van_id: van_id,
      start_time: start_time,
      end_time: end_time,
      id: data.orgId,
    };
    axios
      .post(process.env.REACT_APP_API_BASE_URL + "/change-booking-time", obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
      })
      .then((res) => {
        if (res.data.success) {
          console.log("this is main response",);
          SocketIO.emit("updatebookings", "Update updatebookings");
          toast("Update Successfully");
        }
      });
  };

  const onVisibilityChange = (visible) => {
    localStorage.setItem("creatingBooking", visible);
    if (!visible) {
      getBookingData();
    }
  };

  const formateTime = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const hoursIn12HrFormat = hours % 12 || 12;
    return hoursIn12HrFormat + ":" + minutes + " " + period;
  };

  if (vanData.length > 0) {
    return (
      <>
        {!loginKey ? (
          <Login />
        ) : (
          <div>
            {loader ? <div className="loading">Please Wait</div> : null}
            {/* Toast  */}
            <ToastContainer />

            {/* Van details Popups starts */}
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>{vanDetailsData.name} Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Start */}
                <div className="modal-body">
                  <form
                    id="createVanForm"
                    action="{{route('createVan')}}"
                    method="post"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={(e) =>
                              setVanDetailsData((prevData) => ({
                                ...prevData,
                                name: e.target.value,
                              }))
                            }
                            defaultValue={vanDetailsData?.name}
                            placeholder="Enter name"
                            required
                          />
                        </div>
                      </div>

                      {/* <div className='col-12'>
                        <div className='form-group'>
                          <label htmlFor='plate_number'>Plate Number</label>
                          <input
                            type='text'
                            onChange={ (e) =>
                              setVanDetailsData((prevData) => ({
                                ...prevData,
                                plate_number: e.target.value,
                              }))
                            }
                            defaultValue={ vanDetailsData?.plate_number }
                            className='form-control'
                            name='plate_number'
                            placeholder='Enter number'
                          />
                        </div>
                      </div> */}
                    </div>

                    {/* <div className='row'>
                      <div className='col-12'>
                        <div className='form-group'>
                          <label htmlFor='color'>Color</label>
                          <input
                            type='text'
                            onChange={ (e) =>
                              setVanDetailsData((prevData) => ({
                                ...prevData,
                                color: e.target.value,
                              }))
                            }
                            defaultValue={ vanDetailsData?.color }
                            className='form-control'
                            name='color'
                            placeholder='Enter color'
                            required
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="driver_id">Driver</label>
                          <select
                            onChange={(e) =>
                              setVanDetailsData((prevData) => ({
                                ...prevData,
                                driver_id: e.target.value,
                              }))
                            }
                            defaultValue={vanDetailsData?.driver_id}
                            className="form-control"
                            name="driver_id"
                          >
                            <option value="">Select Driver</option>
                            {vanDriverData.map((driver) => (
                              <option value={driver.id}> {driver.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="groomer_id">Groomer </label>
                          <select
                            onChange={(e) =>
                              setVanDetailsData((prevData) => ({
                                ...prevData,
                                groomer_id: e.target.value,
                              }))
                            }
                            defaultValue={vanDetailsData?.groomer_id}
                            className="form-control"
                            name="groomer_id"
                          >
                            <option value="">Select Groomer</option>
                            {vanDriverData.map((driver) => (
                              <option value={driver.id}> {driver.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={updateVanDetailHandler}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Van details Popups ends */}

            {/* Location Popup start */}
            <Modal size="lg" show={showLocation} onHide={handleCloseLocation}>
              <Modal.Header>
                <Modal.Title>Van Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Start */}
                <div className="modal-body">
                  <form id="createVanForm" method="post">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="name">Location</label>

                          <GooglePlacesAutocomplete
                            className=" form-control "
                            selectProps={{
                              onChange: (place) => {
                                geocodeByAddress(place.label)
                                  .then((results) => getLatLng(results[0]))
                                  .then(({ lat, lng }) => {
                                    // Now you have the latitude and longitude
                                    const addressLat = lat;
                                    const addressLng = lng;

                                    setVanLocationData((prevData) => ({
                                      ...prevData, // Spread the previous data to create a shallow copy
                                      location: place.label,
                                      lat: addressLat,
                                      lng: addressLng,
                                      city: place.label.split(",")[0].trim(),
                                      address: place.label,
                                      van_id: vanLocationData.id,
                                      date: date,
                                    }));

                                    // Do something with the latitude and longitude here...
                                  });
                              },
                            }}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: ["ae"],
                              },
                            }}
                            defaultValue={vanLocationData?.address}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseLocation}>
                  Close
                </Button>
                <Button variant="primary" onClick={locationUpdateHandler}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Location Popup end */}

            <Paper>
              <Scheduler data={data} height={"100%"}>
                <ViewState defaultCurrentDate={currentDate} />
                <EditingState onCommitChanges={onCommitChanges} />
                <IntegratedEditing />

                <EditRecurrenceMenu />
                <WeekView
                  startDayHour={7}
                  endDayHour={24}
                  intervalCount={1.1}
                />
                <Appointments appointmentComponent={appointmentComponent} />
                <AppointmentForm
                  basicLayoutComponent={(props) => (
                    <BasicLayout {...props} date={date} />
                  )}
                  selectComponent={Select}
                  onVisibilityChange={onVisibilityChange}
                />

                <ConfirmationDialog />
                <AllDayPanel />
                <DragDropProvider allowDrag={allowDrag} />
              </Scheduler>
            </Paper>

            {/* All old popups */}
            {childComponent}

            <div className="mainheader shadow-sm position-fixed top-0 start-0 ">
              {/* Map all Vans Data */}
              <div
                className=" w-100  "
                style={{
                  width: "100vw !important",
                }}
              >
                <div
                  onClick={getPreviousBooking}
                  style={{
                    cursor: "pointer",
                    maxHeight: "50px",
                    float: "left",
                    width: "80px",
                  }}
                >
                  <p style={{ paddingTop: "30px" }}>
                    <img
                      src="/previous.png"
                      alt="previous"
                      style={{ width: "20px" }}
                    />
                    <br />
                    {previousDate.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </p>
                </div>
                {vanData.map((van, index) => (
                  <div key={van.id} className={`vanbox-${index + 1} vanbox`}>
                    <h4
                      onClick={() => handleShow(van)}
                      className="cursor-pointer"
                      role="button"
                    >
                      {van.name}
                    </h4>
                    <p
                      // href={vanData[0].location}
                      onClick={() => handleShowLocation(van)}
                      role="button"
                      className="text-sm lh-1 locationtext"
                    >
                      <small>{van.address}</small>
                    </p>
                  </div>
                ))}
                <div
                  onClick={getNextBooking}
                  style={{
                    cursor: "pointer",
                    maxHeight: "50px",
                  }}
                >
                  <p style={{ paddingTop: "30px" }}>
                    <img
                      src="/next.png"
                      alt="next date"
                      style={{ width: "20px" }}
                    />
                    <br />
                    {nextDate.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </p>
                </div>
                <div style={{ clear: "both" }}></div>
                <div className="text-align:center ">
                  <h2>
                    {date.toLocaleDateString("en-US", {
                      weekday: "long",
                    }) + ", "}
                    {date.toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                    <input
                      onChange={(e) => onDateCalenderChange(e.target.value)}
                      style={{
                        width: 38,
                        overflow: "hidden",
                        background: "none",
                        border: "none",
                      }}
                      type="date"
                    />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return !loginKey ? <Login /> : <div>Loading...</div>;
  }
};

export default Dashboard;

function Select(props) {
  return <AppointmentForm.Select {...props} />;
}

function BasicLayout({
  date,
  onFieldChange,
  appointmentData,
  commitChanges,
  ...restProps
}) {
  // const onCustomFieldChange = (nextValue) => {
  //     onFieldChange({ : nextValue });
  // };

  //! Get time from
  // Create a new Date object from the startDate string
  const startDate = new Date(appointmentData.startDate);
  const endDate = new Date(appointmentData.startDate);

  // Get the hours, minutes, and seconds from the dateObject
  const hours = startDate.getHours();
  const minutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes();
  // Construct the time string in the desired format
  const startTime = `${hours < 10 ? "0" + hours : hours}:${
    minutes === 0 ? "00" : minutes
  }`;
  const endTime = `${endHours}:${endMinutes}`;

  //* FORM Booleans
  const [phoneNumberForm, setPhoneNumberForm] = useState(true);
  const [customerExistForm, setCustomerExistForm] = useState(false);
  const [customerFullForm, setCustomerFullForm] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [availableCustomers, setAvailableCustomers] = useState([]);
  const [servicesData, setServicesData] = useState("");
  const [petNames, setPetNames] = useState("");
  const [additionalDiscount, setAdditionalDiscount] = useState("");
  const [totalTime, setTotalTime] = useState(null);
  const [timeType, setTimeType] = useState("minutes");
  const [calculatedEndTime, setCalculatedEndTime] = useState("");
  const [travelTime, setTravelTime] = useState("");
  const [showTravelPopup, setShowTravelPopup] = useState(false);
  const [vanData, setVanData] = useState([]);
  const [distancePopup, setDistancePopup] = useState(false);
  const [currentDistance, setCurrentDistance] = useState(0);
  const [defaultLocation, setDefaultLocation] = useState({
    city: "",
    address: "",
    lat: "",
    lng: "",
  });

  const dateString = appointmentData.startDate;
  const dateObject = new Date(dateString);
  const dayNumber = dateObject.getDate();

  useEffect(() => {
    onFieldChange({
      pet_name: [],
      pet_type: [],
      pet_breed: [],
      pet_health_condition: [],
      grooming_requirement: [],
      services: [],
      van_id: dayNumber,
      payment_method: "Cash",
      // duration: '1 hour',
      city: defaultLocation.city,
      address: defaultLocation.address,
      lat: defaultLocation.lat,
      lng: defaultLocation.lng,
      start_time: startTime,
      location: defaultLocation.address,
      // duration: totalTime / 60 + ' hr',
      end_time: endTime,
      date: moment(date).format("YYYY-MM-DD"),
      travel_time: travelTime,
    });
  }, [defaultLocation]);

  // format the time to 12 hours
  function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(":").map(Number);

    if (hours < 9) {
      hours += 12;
    } else if (hours >= 21) {
      hours -= 12;
    }

    hours = hours % 12 || 12;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  useEffect(() => {
    // Split the end time into hours and minutes
    let [hours, minutes] = endTime.split(":").map((str) => parseInt(str, 10));
    // Add the additional hours
    let additionalTimeInMinutes = totalTime % 60; // Get the remaining minutes after calculating hours
    let additionalHours = Math.floor(totalTime / 60); // Calculate additional hours

    // Add the additional hours and minutes to the existing hours and minutes
    let updatedHours = hours + additionalHours;
    let updatedMinutes = minutes + additionalTimeInMinutes;

    // Adjust the hours and minutes if they exceed their respective limits
    if (updatedMinutes >= 60) {
      updatedHours += Math.floor(updatedMinutes / 60);
      updatedMinutes %= 60;
    }
    // Format the time back into a string
    let updatedTime = `${updatedHours
      .toString()
      .padStart(2, "0")}:${updatedMinutes.toString().padStart(2, "0")}`;
    let returnedTime = convertTo12HourFormat(updatedTime);
    const addAMPM = () => {
      // Extracting the hour from the time string
      let hour = parseInt(returnedTime.split(":")[0]);

      // Checking if the hour is between 1 and 7 (inclusive)
      if (hour >= 1 && hour <= 7) {
        returnedTime += "PM";
      }
    };
    setCalculatedEndTime(returnedTime);

    addAMPM();
    onFieldChange({
      duration: totalTime / 60 + " hr",
      end_time: returnedTime,
    });
  }, [totalTime, onFieldChange]);

  // get vans data
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "/get-vans-data/" +
          localStorage.getItem("currentDate"),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      )
      .then((res) => {
        setVanData(res.data);
      });
  }, []);

  // calculate the distance
  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  };

  const checkDistance = () => {
    const selectedVan = vanData.find((van) => van.id === dayNumber);

    let distance = getDistanceFromLatLonInKm(
      selectedVan.lat,
      selectedVan.lng,
      defaultLocation.lat,
      defaultLocation.lng
    );

    if (distance > 30) {
      setCurrentDistance(distance);
      setDistancePopup(true);
    }
    setPhoneNumberForm(false);
    setCustomerExistForm(true);
  };

  // ! selected services details
  const [allServices, setAllServices] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState(false);

  //! Add Services Form Logic
  const [servicesForms, setServicesForms] = useState([{ id: 1 }]);
  const [petServices, setPetServices] = useState([]);

  // update booking detail for table
  const updateBookingDetails = () => {
    const filteredArray = petServices.filter(
      (value) => value !== null && value !== undefined
    );
    const updatedBookingDetails = filteredArray.map((booking) => {
      const pet = petNames.find((pet) => pet.value === booking.pet_id);
      const bookingServices = booking.pet_services.map((serviceId) => {
        let service;

        for (const category of allServices) {
          service = category.services.find(
            (service) => service.id === serviceId
          );

          if (service) {
            break;
          }
        }

        return {
          servicename: service ? service.name : "",
          price: service ? service.price : "",
          discount: service?.discount ? `${service.discount}%` : "",
          time: service?.time || "",
        };
      });

      return {
        petname: pet ? pet.label : "",
        services: bookingServices,
      };
    });

    setBookingDetails(updatedBookingDetails);
  };

  const petServicesHandler = () => {
    const filteredArray = petServices.filter(
      (value) => value !== null && value !== undefined
    );

    onFieldChange({
      services: filteredArray,
    });
    updateBookingDetails();

    let totalTime = 0;
    filteredArray.map((booking) => {
      booking.pet_services.map((serviceId) => {
        let service;
        for (const category of allServices) {
          service = category.services.find(
            (service) => service.id === serviceId
          );
          if (service) {
            const time = service.time;
            const timeParts = time.split(" ");
            let minutes = 0;

            if (
              timeParts[1].toLowerCase() === "hour" ||
              timeParts[1].toLowerCase() === "hours"
            ) {
              const hours = parseFloat(timeParts[0]);
              minutes = Math.round(hours * 60);
            } else {
              minutes = parseInt(timeParts[0]);
            }

            totalTime += minutes;
          }
        }
        setTotalTime(Number(totalTime));
      });
    });
  };

  const getTotalPrice = () => {
    let total = 0;
    bookingDetails.forEach((booking) => {
      booking.services.forEach((service) => {
        total += service.price;
      });
    });
    return total;
  };

  const handleAddMoreService = (e) => {
    e.preventDefault();
    const newForm = { id: servicesForms.length + 1 };
    setServicesForms([...servicesForms, newForm]);
  };

  useEffect(() => {
    const getTravelTime = async () => {
      const travelTimeReq = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-travel-time`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      );
      const data = await travelTimeReq.json();
      setTravelTime(data?.travel_times[0]?.travel_time);
    };
    getTravelTime();
  }, []);

  useEffect(() => {
    const getServicesData = async () => {
      const searchCustomer = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get-all-services`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loginKey"),
          },
        }
      );
      const data = await searchCustomer.json();
      setAllServices(data?.data);
      const services = data?.data?.flatMap((category) => {
        return [
          { label: category.name, value: null, isDisabled: true },
          ...category.services.map((service) => {
            return { label: service.name, value: service.id };
          }),
        ];
      });
      setServicesData(services);
    };
    getServicesData();
  }, []);

  const searchCustomerHandler = async () => {
    setSearchCustomer(true);
    const searchCustomer = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/get-customer-by-phone/${phoneNumber}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
      }
    );
    const availableCustomer = await searchCustomer.json();
    setAvailableCustomers(availableCustomer);
    setSearchCustomer(false);
  };

  // Create new customer handler
  const createNewHandler = () => {
    setPhoneNumberForm(false);
    setCustomerFullForm(true);
  };

  const getPetNames = async (id) => {
    const petNames = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/customer/pets/${id}`,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("loginKey"),
        },
      }
    );
    const availablePet = await petNames.json();

    // Extracting only "name" and "id" properties from the API response
    const extractedData = availablePet?.data?.map(({ name, type, id }) => ({
      label: type + " - " + name,
      value: id,
    }));

    // let list = [];
    // extractedData.forEach((pet, index) => list.push({ id: index + 1 }))
    // setServicesForms(list);

    setPetNames(extractedData);
  };

  // Avalable customer handler
  const selectCustomerHandler = (customer) => {
    getPetNames(customer.id);
  };

  const handleCloseLocation = () => {
    setShowTravelPopup(false);
  };
  const locationUpdateHandler = () => {
    setShowTravelPopup(false);
  };

  return (
    <>
      {distancePopup ? (
        <Modal
          size="lg"
          show={distancePopup}
          style={{ zIndex: "99999" }}
          onHide={() => setDistancePopup(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center">
            <img src="/info.png" width="50" height="50" alt="warning" />
            <h3 className="text-danger">Warning</h3>
            {/* <p className="mt-3">Van is not available in this location distance is { props.distance.toString().split(".")[0] } KM</p> */}
            <p className="mt-3">
              The distance between the selected customer and Van is{" "}
              {currentDistance.toString().split(".")[0]}
              KM.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => setDistancePopup(false)}
            >
              OK
            </button>
          </Modal.Body>
        </Modal>
      ) : null}

      {/* <AppointmentForm.BasicLayout
                appointmentData={appointmentData}
                onFieldChange={onFieldChange}
                {...restProps}
            ></AppointmentForm.BasicLayout> */}

      {/* ------Phone Number Form------- */}
      {phoneNumberForm && (
        <div className=" w-100 p-4">
          <div className="card">
            <div className="card-header pt-3">
              <h5 className="float-left">Create Booking</h5>
              <button
                className="btn btn-success btn-sm float-end"
                onClick={() => {
                  createNewHandler();
                }}
              >
                Create Customer
              </button>
            </div>
            <div className="card-body">
              <div>
                <div className="col-12">
                  <label htmlFor="phone">Search By Phone,Mobile and Name</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    placeholder="Enter phone,mobile and name"
                    id="phone"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchCustomerHandler();
                      }
                    }}
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <button
                    disabled={searchCustomer}
                    type="button"
                    onClick={searchCustomerHandler}
                    className="btn btn-primary w-100 mt-4"
                  >
                    {searchCustomer ? (
                      <>
                        {" "}
                        Please Wait...{" "}
                        <img
                          src="/loadingz.gif"
                          width={25}
                          alt="loading"
                        />{" "}
                      </>
                    ) : (
                      "Search"
                    )}
                  </button>
                </div>

                {availableCustomers?.data?.length > 0 &&
                  availableCustomers.data.map((customer) => (
                    <div key={customer.phone}>
                      <br />
                      <div
                        className="alert alert-primary d-flex justify-content-between align-items-center mb-n2 rounded-pill"
                        role="alert"
                      >
                        <div>
                          <strong>Name: </strong>
                          {customer.name}
                          {"  "}
                          <strong>Phone: </strong>
                          {customer.phone}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onMouseDown={() => {
                              selectCustomerHandler(customer);
                              setDefaultLocation({
                                city: customer.city,
                                address: customer.address,
                                lat: customer.lat,
                                lng: customer.lng,
                              });
                              onFieldChange({
                                customer_id: customer.id,
                              });
                            }}
                            onClick={checkDistance}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                {availableCustomers?.data?.length < 1 && (
                  <div className="alert alert-danger mt-5" role="alert">
                    Customer not found{" "}
                    <strong role="button" onClick={createNewHandler}>
                      {" "}
                      Create new
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </div>

          <br />
        </div>
      )}

      {/* -------Customer Available Form-------  */}
      {customerExistForm && (
        <div className=" w-100 p-1">
          <form id="createCustomerForm ">
            {/* Customer Details */}
            <div className="card">
              <h5 className="card-header pt-3">Create Bookings</h5>
              <div className="card-body">
                {/* Address */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="location">Address</label>
                      <Autocomplete
                        className=" form-control "
                        defaultValue={defaultLocation.address}
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        options={{
                          types: ["address"],
                          componentRestrictions: { country: "ae" }, // Restrict to UAE
                        }}
                        onPlaceSelected={(place) => {
                          onFieldChange({
                            location: place.formatted_address,
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                            city: place.formatted_address.split(",")[0].trim(),
                            start_time: startTime,
                            end_time: endTime,
                            address: place.formatted_address,
                            date: moment(date).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ADD MORE SERVICES FORM */}

                <div className="card">
                  <h5 className="card-header pt-3 d-flex justify-content-between align-items-center">
                    <p>Services</p>
                    <button
                      onClick={handleAddMoreService}
                      className="btn btn-success rounded btn-sm"
                    >
                      Add more
                    </button>
                  </h5>
                  {petNames ? (
                    <>
                      {petNames.map((form, index) => (
                        <div key={form.value}>
                          <div className="card-header pt-3 d-flex justify-content-between align-items-center">
                            <h5>Service {index + 1}</h5>
                          </div>

                          {/* Add your form elements here */}
                          <div className="card-body">
                            {/* ADD MORE Services Form fields */}
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="discount">
                                    Pet {index + 1} Name
                                  </label>
                                  <input
                                    value={form.label}
                                    className="form-control"
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="discount">
                                    Select Services
                                  </label>
                                  <ReactSelect
                                    isMulti
                                    name="colors"
                                    options={servicesData}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                      setPetServices((prevState) => {
                                        const updatedPetServices = [
                                          ...prevState,
                                        ]; // Create a copy of the petServices array
                                        updatedPetServices[index] = {
                                          pet_id: form.value,
                                          pet_services: e.map(
                                            ({ value }) => value
                                          ),
                                        }; // Update the specific index with the new object

                                        return updatedPetServices;
                                      });
                                      // updateBookingDetails();
                                    }}
                                    onMouseLeave={updateBookingDetails}
                                    onBlur={petServicesHandler}
                                    onInput={updateBookingDetails} // onChange={(e) =>
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>

                <br />
                {/* Payment method */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="payment">Payment Method </label>
                      <select
                        name="payment"
                        required
                        className="form-control"
                        id="payment"
                        onChange={(e) =>
                          onFieldChange({
                            payment_method: e.target.value,
                          })
                        }
                      >
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="Online">Online</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* Duration */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="duration">Duration </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="time duration "
                        disabled
                        required
                        defaultValue={
                          timeType === "minutes" ? totalTime : totalTime / 60
                        }
                        onMouseOver={(e) => {
                          onFieldChange({
                            duration: totalTime / 60 + "hr",
                          });
                          // setTotalTime(totalTime + Number(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="durationtype">Duration type </label>
                      <select
                        name="durationtype"
                        className="form-control"
                        id="durationtype"
                        defaultValue={"minutes"}
                        onChange={(e) => {
                          // onFieldChange((prev) => ({
                          //   ...prev,
                          //   duration: prev.duration + 'hr',
                          // }));
                          setTimeType(e.target.value);
                        }}
                      >
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* Discount */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">
                        Discount in <strong>AED</strong>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Discount in AED"
                        onChange={(e) => {
                          onFieldChange({
                            additional_discount: e.target.value,
                          });
                          setAdditionalDiscount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* Travel time */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">
                        Travel time
                        <button type="button" className="btn btn-link btn-sm">
                          edit
                        </button>
                      </label>
                      <input
                        type="number"
                        defaultValue={travelTime}
                        onChange={(e) =>
                          onFieldChange({
                            travel_time: e.target.value + " Minutes",
                          })
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="discount">Date</label>
                      <input
                        type="date"
                        defaultValue={date.toISOString().split("T")[0]}
                        onChange={(e) =>
                          onFieldChange({
                            date: moment(e.target.value).format("YYYY-MM-DD"),
                          })
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="discount">Start time</label>
                      <input
                        type="time"
                        defaultValue={startTime}
                        onChange={(e) =>
                          onFieldChange({
                            start_time: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="endtime">End Time</label>
                      <input
                        type="time"
                        value={calculatedEndTime}
                        onChange={(e) => {
                          setCalculatedEndTime(e.target.value);
                          onFieldChange({
                            end_time: e.target.value,
                          });
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Special Instruction</label>
                      <textarea
                        className="form-control"
                        cols="30"
                        rows="5"
                        onChange={(e) =>
                          onFieldChange({ description: e.target.value })
                        }
                        placeholder="Enter Booking Instruction"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* Booking Data in Table */}
          <br />
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Pet</th>
                <th scope="col">Service</th>
                <th scope="col">Duration</th>
                <th scope="col">Discount</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              {bookingDetails.map((booking, index) => (
                <tr key={index}>
                  <td>{booking.petname}</td>
                  <td>
                    {booking.services.map((service, index) => (
                      <div key={index}>{service.servicename}</div>
                    ))}
                  </td>

                  <td>
                    {booking.services.map((service, index) => (
                      <div key={index}>{service.time}</div>
                    ))}
                  </td>

                  <td>
                    {booking.services.map((service, index) => (
                      <div key={index}>{service.discount}</div>
                    ))}
                  </td>

                  {/* <td>10%</td> */}
                  <td>
                    {booking.services.map((service, index) => (
                      <div key={index}>{service.price}</div>
                    ))}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>

                <td></td>
                <td></td>
                <th>Sub Total</th>
                <th> {getTotalPrice()}</th>
              </tr>
              <tr>
                <td></td>

                <td></td>
                <td></td>
                <th>Discount</th>
                <th> {additionalDiscount}</th>
              </tr>
              <tr>
                <td></td>
                <td></td>

                <td></td>
                <th>Total</th>
                <th> {getTotalPrice() - additionalDiscount}</th>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* -------Create Customer full Form------- */}
      {customerFullForm && (
        <CreateCustomer
          onFieldChange={onFieldChange}
          appointmentData={appointmentData}
          defaultLocation={defaultLocation}
        />
      )}

      {/* Travel time Form */}
      {/* Location Popup start */}
      <Modal size="lg" show={showTravelPopup} onHide={handleCloseLocation}>
        <Modal.Header>
          <Modal.Title>Update Travel time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Start */}
          <div className="modal-body">
            <form id="createVanForm" method="post">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="name">Travel time</label>
                    <input
                      type="text"
                      className=" form-control"
                      defaultValue={travelTime}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* End */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLocation}>
            Close
          </Button>
          <Button variant="primary" onClick={locationUpdateHandler}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
