import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './dashboard';

function App() {
  return (
    <Dashboard />
  );
}

export default App;
