import axios from "axios";
import React, { useState } from "react";



const Login = () => {
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const code = e.target.code.value;
    axios.post(process.env.REACT_APP_API_BASE_URL + '/login', { code }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        if (res.data.code === 200) {
          localStorage.setItem('loginKey', res.data.user);
          window.location.href = "/";
        } else {
          setError(res.data.msg)
        }
      }
      );
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Enter Access Code</h2>
        <form onSubmit={ handleSubmit }>
          <input onChange={ () => setError(null) } className="form-control" name="code" type="text" placeholder="Access Code" />
          <button className="btn btn-primary w-100 mt-2" type="submit">Submit</button>
          { error && <div className="text-danger mt-1">{ error }</div> }
        </form>
      </div>
    </div>

  )
}

export default Login;