
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import 'select2';
import axios from 'axios';
import ReactSelect from 'react-select';
import { SocketIO } from '../utils/helper';

const moment = require('moment');

const EditBooking = (props) => {
  const [lgShow, setLgShow] = useState(true);
  const [services, setServices] = useState(props.services);
  const [times, setTimes] = useState([]);
  const [duration, setDuration] = useState(0);
  const [date, setDate] = useState(props.bookingData.date);
  const [durationType, setDurationType] = useState(null);
  const [selectedServices, setSelectedServices] = useState(props.selectedServices);
  const [paymentMethod, setPaymentMethod] = useState(props.bookingData.payment_method);
  const [vanId, setVanId] = useState(props.bookingData.van_id);
  const [startTime, setStartTime] = useState(props.bookingData.start_time);
  const [discount, setDiscount] = useState(props.bookingData.discount);
  const [description, setDescription] = useState(props.bookingData.description);
  const [endTime, setEndTime] = useState(props.bookingData.end_time);
  const [AllServices, setAllServices] = useState([]);
  const [petServices, setPetServices] = useState([]);


  const selectRef = useRef(null);

  useEffect(() => {


    const services = props.services.flatMap((category) => {
      return [
        { label: category.name, value: null, isDisabled: true },
        ...category.services.map((service) => {
          return { label: service.name, value: service.id };
        }),
      ];
    });
    setAllServices(services);
  }, []);

  useEffect(() => {
    $('#updateSelectedServices').val(props.selectedServices).trigger('change');
  }, [services]);

  useEffect(() => {
    const current_time = new Date();
    const current_minute = current_time.getMinutes();

    // Determine the next multiple of 5 minutes after the current time
    let start_time;
    if (current_minute % 5 === 0) {
      start_time = current_time;
    } else {
      start_time = new Date(current_time.getTime() + (5 - (current_minute % 5)) * 60000);
    }

    // Create a Date object for 7:05 PM today
    const end_time = new Date();
    end_time.setHours(19);
    end_time.setMinutes(5);

    // Loop through the times and add them to the times array
    const newTimes = [];
    while (start_time < end_time) {
      newTimes.push(start_time.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }));
      start_time = new Date(start_time.getTime() + 5 * 60000);
    }
    setTimes(newTimes);
  }, []);

  const calculateDuration = (totalSeleted) => {
    let currentduration = 0;
    setDuration(currentduration);
    if (totalSeleted.length > 0) {
      totalSeleted.forEach((service) => {
        services.forEach((eachpet) => {
          eachpet.services.filter((eachservice) => {
            if (eachservice.id === service) {
              if (eachservice.time != null && eachservice.time !== "" && eachservice.time != 0 && eachservice
                .time !== "+") {
                // Add the time to the duration in minutes or hours, depending on the unit of time
                if (eachservice.time > 5) {
                  currentduration += Number(eachservice.time) / 60; // divide by 60 to convert from minutes to hours
                } else {
                  currentduration += Number(eachservice.time); // time is in minutes
                }
              }
            }
          });
        });
      });
      let hours = Math.floor(currentduration);
      let remainingMinutes = Math.round((currentduration - hours) * 60);
      if (remainingMinutes >= 60) {
        let remainhour = Math.floor(remainingMinutes / 60);
        hours = hours + remainhour;
        remainingMinutes = remainingMinutes - 60;
      }
      currentduration = hours > 0 ? hours + "." + remainingMinutes : remainingMinutes;
      setDuration(currentduration);
      if (hours > 0) {
        setDurationType("hour");
      } else {
        setDurationType("minute");
      }
    }
  }

  const onFinish = () => {
    let data = {
      "id": props.bookingData.id,
      "date": date,
      "start_time": startTime,
      "end_time": endTime,
      "services": selectedServices,
      "duration": duration + " " + durationType,
      'van_id': vanId,
      'location': props.bookingData.location,
      'city': props.bookingData.city,
      'lat': props.bookingData.lat,
      'lng': props.bookingData.lng,
      'payment_method': paymentMethod,
      'discount': discount,
      'address': props.bookingData.address,
      'pet_services': petServices,
      'description': description,
    }
    axios.post(process.env.REACT_APP_API_BASE_URL + '/bookings/update', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("loginKey")
      }
    })
      .then((response) => {
        if (response.data.status === 200) {
          props.onClose(true);
          SocketIO.emit('updatebookings', 'Update updatebookings')
        }
      })
  }


  function handleSelectChange() {
    const selectedValues = $(selectRef.current).val();
    setSelectedServices(selectedValues);
    calculateDuration(selectedValues);
  }

  const onTimeChange = (e) => {
    let starttime = e.target.value;
    let endTime = moment(starttime, 'hh:mm A').add(duration, durationType).format('hh:mm A');
    setStartTime(starttime);
    setEndTime(endTime);
  }

  useEffect(() => {
    setDuration(props.bookingData.duration.split(" ")[0])
    setDurationType(props.bookingData.duration.split(" ")[1])
    $(selectRef.current).select2().on("change", handleSelectChange);
  }, []);

  return (
    <Modal
      size="lg"
      backdrop="static"
      keyboard={ false }
      show={ lgShow }
      onHide={ () => props.onClose() }
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header >
        <Modal.Title id="example-modal-sizes-title-lg">
          Update Booking
        </Modal.Title>
        <button
          type='button'
          className='btn btn-outline-danger'
          onClick={ () => props.onClose() }
        >
          {/* &times; */ }X
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <div style={ {
            height: '80vh',
            overflowY: 'auto',
            paddingRight: '15px',
          } }>
            <strong>Customer Detail</strong>
            <table className="table table-bordered mt-2">
              <tr>
                <th>Name</th>
                <td>{ props.bookingData.customer.name }</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{ props.bookingData.customer.phone }</td>
              </tr>
              <tr>
                <th width="30%">Address</th>
                <td>{ props.bookingData.customer.customerinfo.address }</td>
              </tr>
            </table>


            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Date</Form.Label>
              <Form.Control onChange={
                (e) => {
                  setDate(e.target.value);
                }
              } type="date" placeholder="Enter date" value={ date } />
            </Form.Group>
            { props.bookingData.petsWithServices.map((eachservice, index) =>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label htmlFor='discount'>Pet { index + 1 } : { eachservice.customer_pet.type } - { eachservice.customer_pet.name } </label>

                    <ReactSelect
                      isMulti
                      options={ AllServices }
                      defaultValue={ eachservice.services }
                      onChange={ (e) => {
                        setPetServices((prevState) => {
                          const updatedPetServices = [...prevState]; // Create a copy of the petServices array
                          updatedPetServices[index] = {
                            pet_id: eachservice.customer_pet.id,
                            pet_services: e.map(({ value }) => value),
                          }; // Update the specific index with the new object

                          return updatedPetServices;
                        });
                      } }
                    />
                  </div>
                </div>
              </div>

            ) }

            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Payment Method</Form.Label>
              <select onChange={
                (e) => setPaymentMethod(e.target.value)
              } className='form-control' value={ paymentMethod }>
                <option value="Cash">Cash</option>
                <option value="Card">Card</option>
                <option value="Online">Online</option>
              </select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Van</Form.Label>
              <select value={ vanId } onChange={
                (e) => setVanId(e.target.value)
              } className='form-control' >
                { props.vanData.map((eachvan) =>
                  <option value={ eachvan.id } selected={ eachvan.id === props.bookingData.van_id }>{ eachvan.name }</option>
                ) }
              </select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Discount</Form.Label>
              <Form.Control onChange={
                (e) => {
                  setDiscount(e.target.value);
                }
              } type="number" placeholder="Enter discount in AED" value={ discount } />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Booking Time</Form.Label>
              <Form.Control onChange={
                (e) => {
                  setStartTime(e.target.value);
                }
              } type="time" placeholder="Enter start time" value={ startTime } />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>End Time</Form.Label>
              <Form.Control onChange={
                (e) => {
                  setEndTime(e.target.value);
                }
              } type="time" placeholder="Enter end time" value={ endTime } />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail" >
              <Form.Label>Instruction</Form.Label>
              <textarea rows={ 5 } className='form-control' placeholder="Enter instruction" value={ description } onChange={
                (e) => {
                  setDescription(e.target.value);
                }
              }></textarea>
            </Form.Group>



          </div>
          <Button onClick={ onFinish } className='float-right' variant="success">
            Update
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default EditBooking
