import Autocomplete from 'react-google-autocomplete';
import React, { useState } from 'react';
import axios from 'axios';

const CreateCustomer = (props) => {
  const [petForms, setPetForms] = useState([{ id: 1 }]);
  const [locationUrl, setLocationUrl] = useState('');
  const [locationAddress, setLocationAddress] = useState('');

  const handleAddMorePet = (e) => {
    e.preventDefault();
    const newForm = { id: petForms.length + 1 };
    setPetForms([...petForms, newForm]);
  };

  const onEnterLocationUrl = () => {
    let url = locationUrl;
    const myArray = url.split("%2C");
    let lat = 0;
    let lng = 0;
    if (myArray.length > 1) {
      lat = myArray[0].split("q=")[1];
      lng = myArray[1].split("&z")[0]
    } else {
      const myArray = url.split("!3d");
      const myArray2 = myArray[1].split("!4d");
      if (myArray2.length < 2) {
        alert("incorrect location url")
        return;
      }
      lat = myArray2[0];
      lng = myArray2[1].split("!")[0];
      if (myArray2[1].split("!")[0].split('?').length > 0) {
        lng = myArray2[1].split("!")[0].split('?')[0];
      }
    }
    axios
      .get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng +
        "&key=" + process.env.REACT_APP_GOOGLE_API_KEY, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((object) => {
        let address = object.data.results[0].formatted_address;
        if (address != null) {
          props.onFieldChange({
            location: address,
            lat: lat,
            lng: lng,
            city: address.split(',')[0].trim(),
            address: address,
            location_link: url,
          });
          setLocationAddress(address);
        } else {
          alert("incorrect location url")
        }
      });

  }

  const handleRemove = (id) => {
    if (petForms.length === 1) {
      return; // Prevent removal if only one form is present
    }
    const updatedForms = petForms.filter((form) => form.id !== id);
    setPetForms(updatedForms);
  };

  return (
    <div>
      <form id='createCustomerForm'>
        {/* Customer Details */ }
        <div className='card'>
          <h5 className='card-header pt-3'>Customer Detail</h5>
          <div className='card-body'>
            <div className='row'>
              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='name'>
                    First Name
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='first_name'
                    onChange={ (e) =>
                      props.onFieldChange({
                        first_name: e.target.value,
                      })
                    }
                    placeholder='Enter name'
                    id='first_name'
                    required
                  />
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='name'>
                    Last Name <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='last_name'
                    placeholder='last name'
                    onChange={ (e) =>
                      props.onFieldChange({
                        last_name: e.target.value,
                      })
                    }
                    id='last_name'
                    required
                  />
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='phone'>
                    Phone <span className='text-danger'>*</span>
                  </label>
                  <input
                    id='phone'
                    type='text'
                    className='form-control'
                    name='phone'
                    placeholder='phone'
                    onChange={ (e) =>
                      props.onFieldChange({
                        phone: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>

              <div className='col-6'>
                <div className='form-group'>
                  <label htmlFor='mobile'>Mobile </label>
                  <input
                    type='text'
                    className='form-control'
                    name='mobile'
                    placeholder='mobile'
                    onChange={ (e) =>
                      props.onFieldChange({
                        mobile: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='email'>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    placeholder='Enter email'
                    onChange={ (e) =>
                      props.onFieldChange({
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Address Details */ }
        <div className='card'>
          <h5 className='card-header pt-3'>Address Information</h5>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='location'>Location</label>
                  <div className='row'>
                    <div className='col-10'>
                      <input type='text' onChange={ (e) => setLocationUrl(e.target.value) } className='form-control' placeholder='Enter location link' />
                    </div>
                    <div className='col-2'>
                      <button type='button' className='btn btn-primary' onClick={ onEnterLocationUrl }>Search</button>
                    </div>

                  </div>
                </div>
                <div className='form-group'>
                  <label htmlFor='location'>Address</label>
                  <Autocomplete
                    className='form-control'
                    apiKey={ process.env.REACT_APP_GOOGLE_API_KEY }
                    defaultValue={ locationAddress }
                    options={ {
                      types: ['address'],
                      componentRestrictions: { country: 'ae' }, // Restrict to UAE
                    } }
                    onPlaceSelected={ (place) => {
                      props.onFieldChange({
                        location: place.formatted_address,
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        city: place.formatted_address.split(',')[0].trim(),
                        address: place.formatted_address,
                      });
                    } }
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='location'>Mailing Street</label>
                  <input
                    type='text'
                    className='form-control'
                    name='mailingstreet'
                    placeholder='Enter Mailing Street'
                    onChange={ (e) =>
                      props.onFieldChange({
                        mailingstreet: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='address'>Other Address</label>
                  <Autocomplete
                    apiKey={ process.env.REACT_APP_GOOGLE_API_KEY }
                    options={ {
                      types: ['address'],
                      componentRestrictions: { country: 'ae' }, // Restrict to UAE
                    } }
                    onPlaceSelected={ (place) => {
                      props.onFieldChange({
                        other_address: place.formatted_address,
                        other_city: place.formatted_address,
                      });
                    } }
                    style={ {
                      width: '100%',
                      padding: '5px',
                      border: '1px solid gray',
                      borderRadius: '4px',
                    } }
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='city'>Other City</label>
                  <input
                    type='text'
                    className='form-control'
                    name='other_city'
                    placeholder='Enter city'
                    onChange={ (e) =>
                      props.onFieldChange({
                        other_city: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label htmlFor='description'>Description</label>
                  <textarea
                    className='form-control'
                    name='description'
                    placeholder='Type here'
                    onChange={ (e) =>
                      props.onFieldChange({
                        description: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pet Details */ }
        <div className='card'>
          {/* <h5 className="card-header pt-3 flex flex-row justify-content-between align-items-center">
                                <p> Pet Detail </p>
                                <p className="   ">Add more pet</p>
                            </h5> */}
          <div className='card'>
            <h5 className='card-header pt-3 d-flex justify-content-between align-items-center'>
              <p>Pet Detail</p>
              <button
                onClick={ handleAddMorePet }
                className='btn btn-success rounded btn-sm'
              >
                Add more
              </button>
            </h5>
          </div>
          { petForms.map((form) => (
            <div key={ form.id }>
              <div className='card-header pt-3 d-flex justify-content-between align-items-center'>
                <h5>Pet { form.id } Detail</h5>
                <button
                  className='btn btn-danger btn-sm'
                  style={ {
                    backgroundColor: 'transparent',
                    borderColor: 'red',
                    color: 'red',
                  } }
                  onClick={ () => handleRemove(form.id) }
                >
                  Remove
                </button>
              </div>

              {/* Add your form elements here */ }
              <div className='card-body'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label htmlFor='pet_name'>Pet Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='pet_name'
                        placeholder='Enter pet name'

                        onBlur={ (e) => {
                          const updatedPetNames = [...props.appointmentData.pet_name];
                          updatedPetNames[form.id - 1] = e.target.value;
                          props.onFieldChange({ pet_name: updatedPetNames });
                        } }
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label htmlFor='breed'>Breed</label>
                      <input
                        type='text'
                        className='form-control'
                        name='pet_breed'
                        placeholder='Enter Breed'
                        onBlur={ (e) => {
                          const updatedPetBreeds = [...props.appointmentData.pet_breed];
                          updatedPetBreeds[form.id - 1] = e.target.value;
                          props.onFieldChange({ pet_breed: updatedPetBreeds });
                        } }
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='form-group'>
                      <label htmlFor='mobile'>Pet Type </label>
                      <select
                        className='form-control'
                        name='pet_type'
                        onBlur={ (e) => {
                          const updatedPetTypes = [...props.appointmentData.pet_type];
                          updatedPetTypes[form.id - 1] = e.target.value;
                          props.onFieldChange({ pet_type: updatedPetTypes });
                        } }
                      >
                        <option value=''>None</option>
                        <option value='Cat'>Cat</option>
                        <option value='Dog'>Dog</option>
                        <option value='Rabbit'>Rabbit</option>
                        <option value='Bird'>Bird</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label htmlFor='breed'>Pet Health Condition</label>
                      <textarea
                        className='form-control'
                        rows={ 3 }
                        name='pet_health_condition'
                        placeholder='Type here'
                        onBlur={ (e) => {
                          const updatedHealthConditions = [...props.appointmentData.pet_health_condition];
                          updatedHealthConditions[form.id - 1] = e.target.value;
                          props.onFieldChange({ pet_health_condition: updatedHealthConditions });
                        } }
                      ></textarea>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-group'>
                      <label htmlFor='breed'>
                        Specific Grooming Requirement (if any)
                      </label>
                      <textarea
                        className='form-control'
                        name='grooming_requirement'
                        rows={ 3 }
                        placeholder='Type here'
                        onBlur={ (e) => {
                          const updatedGroomingRequirements = [...props.appointmentData.grooming_requirement];
                          updatedGroomingRequirements[form.id - 1] = e.target.value;
                          props.onFieldChange({ grooming_requirement: updatedGroomingRequirements });
                        } }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) }
        </div>
      </form>
    </div>
  )
}

export default CreateCustomer