import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';

const DistanceAlert = (props) => {
  return (
    <Modal
      size='lg'
      show={ true }
      onHide={ () => props?.onAlertClose(props?.data) }
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body className='text-center'>
        <img src='/info.png' width='50' height='50' alt='warning' />
        <h3 class='text-danger'>Warning</h3>
        {/* <p className="mt-3">Van is not available in this location distance is { props.distance.toString().split(".")[0] } KM</p> */ }
        <p className='mt-3'>
          You are updating the booking between cities the distance { props.distance.toString().split(".")[0] }KM from customer location.
        </p>
        <button
          className='btn btn-primary'
          onClick={ () => props?.onAlertClose(props?.data) }
        >
          OK
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default DistanceAlert;
