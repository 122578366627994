import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import EditBooking from './editbooking';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2'

const BookingDetail = (props) => {
  const [lgShow, setLgShow] = useState(true);
  const [childComponent, setChildComponent] = useState(null);
  const [services, setServices] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + '/get-pets', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem("loginKey"),
        },
      })
      .then((response) => {
        if (response.data.pets) {
          setServices(response.data.pets);
        }
      });

    let total = 0;

    props.bookingData.booking_services.forEach(each => {
      total += each.discount ? each.service.price - (each.service.price * each.discount.discount) / 100 : each.service.price;
    });
    setTotalPrice(total);
  }, []);

  const onClose = (data) => {
    if (data) {
      setChildComponent(null);
      props.onEditBooking();
    } else {
      props.setChildComponent(null);
    }
  };

  const deleteBooking = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/delete-booking/' + data.id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("loginKey"),
          },
        }).then((response) => {
          if (response.data.code === 200) {
            Swal.fire(
              'Deleted!',
              'Booking has been deleted.',
              'success'
            ).then(() => {
              onClose(true);
            }
            )
          }
        })
      }
    })
  }

  const onEditBooking = (data) => {

    props.setChildComponent(
      <EditBooking
        selectedServices={ props.otherdata.selectedService }
        services={ services }
        bookingData={ data }
        onClose={ onClose }
        vanData={ props.vanData }
      />
    );
  };

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    return `${formattedHours}:${minutes} ${ampm}`;
  };

  return (
    <>
      { childComponent ? childComponent : null }
      <Modal
        size='lg'
        backdrop="static"
        keyboard={ false }
        show={ lgShow }
        onHide={ () => props.onClose() }
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            Booking Detail
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-lg'>
            Booking Detail
          </Modal.Title>
          <button
            type='button'
            className='btn btn-outline-danger'
            onClick={ () => props.onClose() }
          >
            {/* &times; */ }X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div style={ {
            height: '80vh',
            overflowY: 'auto'
          } }>
            <strong>Customer Detail</strong>
            <table className='table table-bordered mt-2'>
              <tr>
                <th>Name</th>
                <td>{ props.bookingData.customer.name }</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{ props.bookingData.customer.phone }</td>
              </tr>
              <tr>
                <th width='30%'>Address</th>
                <td>
                  <a style={ { color: 'blue' } } href={ props.bookingData.customer.customerinfo.location_link } target='_blank'> { props.bookingData.address }</a>
                </td>
              </tr>
            </table>
            <strong>Booking Detail</strong>
            <table className='table table-bordered mt-2'>
              <tr>
                <th>Date</th>
                <td>{ props.bookingData.date }</td>
              </tr>
              <tr>
                <th>Time</th>
                <td>
                  { convertTo12HourFormat(props.bookingData.start_time) } -{ ' ' }
                  { convertTo12HourFormat(props.bookingData.end_time) }
                </td>
              </tr>
              <tr>
                <th>Total Duration</th>
                <td>
                  { props.bookingData.duration }
                </td>
              </tr>
              <tr>
                <th>Pets & Services</th>
                <td>
                  { props.bookingData.petsWithServices.map((pet, index) => <div> { pet.services.length > 0 ? <> <strong>{ index + 1 }. { pet.customer_pet.name }</strong><br />
                    { pet.services.map((service, index) => <div className='ml-3'> { index + 1 }. { service.label } </div>) }
                  </> : null }</div>) }
                </td>
              </tr>
              <tr>
                <th width='30%'>Payment Method</th>
                <td>{ props.bookingData.payment_method }</td>
              </tr>
              <tr>
                <th>Total Price</th>
                <td>{ props.bookingData.total_price } AED</td>
              </tr>
              <tr>
                <th>Furbaby </th>
                <td>
                  <a
                    href={
                      process.env.REACT_APP_MAIN_URL +
                      '/fur-baby-form/' +
                      props.bookingData.uuid
                    }
                    target='_blank'
                  >
                    Fur Baby Information
                  </a>
                </td>
              </tr>
              { props.bookingData.invoice_no ? (
                <tr>
                  <th>Payment Link </th>
                  <td>
                    <a href={ props.bookingData.invoice_no.url } target='_blank'>
                      Payment Link
                    </a>
                  </td>
                </tr>
              ) : null }
              <tr>
                <th>Instructions 📄 </th>
                <td>
                  { props.bookingData.description }
                </td>
              </tr>
            </table>
            <strong>Service Detail</strong>
            <table className='table table-bordered mt-2'>
              <tr>
                <th>Pet</th>
                <th>Service Name</th>
                <th>Duration</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Price After Discount</th>
              </tr>

              { props.bookingData.booking_services.map((each, key) => (

                <tr key={ key }>
                  <td>{ each.customer_pet.type + ' - ' + each.customer_pet.name }</td>
                  <td>{ each.service.name }</td>
                  <td>
                    { each.service.time } { each.service.time_type }
                  </td>
                  <td>AED { each.service.price }</td>
                  <td>{ each.discount ? each.discount.discount + '%' : '' }</td>
                  <td>
                    AED{ ' ' }
                    { each.discount ? each.service.price - (each.service.price * each.discount.discount) / 100 : each.service.price }

                  </td>
                </tr>
              )) }
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{ (totalPrice - props.bookingData.total_price) > 0 ? <>{ (totalPrice - props.bookingData.total_price) } AED </> : null }</th>
                  <th>{ (totalPrice - props.bookingData.total_price) > 0 ? <> { totalPrice - (totalPrice - props.bookingData.total_price) } AED</> : "AED " + totalPrice }</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className='text-right'>
            <button
              className='btn btn-danger mr-3'
              onClick={ () => deleteBooking(props.bookingData) }
            >
              Delete Booking
            </button>
            <button
              className='btn btn-primary'
              onClick={ () => onEditBooking(props.bookingData) }
            >
              Edit Booking
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookingDetail;
