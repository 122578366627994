import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { SocketIO } from '../utils/helper';
import axios from 'axios';

const PaymentStatus = (props) => {
  const [lgShow, setLgShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(props.bookingData.status);
  const [is_paid, setIsPaid] = useState(props.bookingData.is_paid);
  const [paymentMethod, setPaymentMethod] = useState(props.bookingData.payment_method);

  const handleSubmit = () => {
    setLoader(true);
    let payload = {
      id: props.bookingData.orgId,
      status: status,
      is_paid: is_paid,
      payment_method: paymentMethod
    }
    axios.post(process.env.REACT_APP_API_BASE_URL + "/store-booking-status/", payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("loginKey")
      }
    }).then(res => {
      if (res.data.success) {
        SocketIO.emit('updatebookings', 'Update updatebookings')
        setLoader(false);
        props.onClose();
      } else {
        setLoader(false);
        SocketIO.emit('updatebookings', 'Update updatebookings')
        props.onClose();
      }
    });
  }

  return (
    <>
      <Modal
        size="lg"
        show={ lgShow }
        backdrop="static"
        keyboard={ false }
        onHide={ () => props.onClose() }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Payment Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { loader ? <div className="loading">Please Wait</div> : null }
          <div className="form-group">
            <label>Booking Status</label>
            <select onChange={ (e) => setStatus(e.target.value) } className="form-control" name="status">
              <option value="0" selected={ props.bookingData.status === 0 ? true : false }>Pending</option>
              <option value="1" selected={ props.bookingData.status === 1 ? true : false }>Confirmed</option>
              <option value="2" selected={ props.bookingData.status === 2 ? true : false }>complete</option>
              <option value="3" selected={ props.bookingData.status === 3 ? true : false }>Cancelled</option>
            </select>
          </div>
          <div className="form-group">
            <label>Payment Method</label>
            <select onChange={ (e) => setPaymentMethod(e.target.value) } className="form-control" name="is_paid">
              <option value="Cash" selected={ props.bookingData.payment_method === 'Cash' ? true : false }>Cash</option>
              <option value="Online" selected={ props.bookingData.payment_method === 'Online' ? true : false }>Online</option>
              <option value="Card" selected={ props.bookingData.payment_method === 'Card' ? true : false }>Card</option>
            </select>
          </div>
          <div className="form-group">
            <label>Payment Status</label>
            <select onChange={ (e) => setIsPaid(e.target.value) } className="form-control" name="is_paid">
              <option value="0" selected={ props.bookingData.is_paid === 0 ? true : false }>Unpaid</option>
              <option value="1" selected={ props.bookingData.is_paid === 1 ? true : false }>Paid</option>
            </select>
          </div>
          <div className="form-group">
            <button disabled={ loader } onClick={ handleSubmit } className="btn btn-primary" >Save</button>
          </div>
        </Modal.Body>
      </Modal >
    </>

  );
}

export default PaymentStatus;
